<script lang="ts" setup>
const { t } = useT();
const { refresh } = useAppInitData();

const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();

const switchToEntries = async () => {
	await handleSwitchGameMode();
	refresh();
};

const handleOpenCash = () => {
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<div class="switcher">
		<div class="content">
			<AText as="h2" type="h6 h5-md" class="switcher__title mb-2">{{ t("Ready to snag some real prizes?") }}</AText>

			<AText type="base" as="p" class="switcher__sub-title mb-4">
				<i18n-t keypath="Flip over to {key} and let the fun begin!" tag="span">
					<template #key>
						<b>{{ t("Secret Coins mode") }}</b> –
					</template>
				</i18n-t>
				<AText
					type="base"
					class="d-block d-md-inline ml-md-1 link-text"
					:modifiers="['link', 'underline']"
					@click="navigateTo('/how-it-works')"
					>{{ t("Find out more") }}</AText
				>
			</AText>

			<AButton v-if="!isSweepStakes" variant="primary" size="md" class="switcher__btn" @click="switchToEntries">{{
				t("switch now")
			}}</AButton>

			<AButton v-else variant="primary" size="md" class="switcher__btn" @click="handleOpenCash">
				{{ t("get coins") }}
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.switcher {
	width: 100%;
	min-height: 184px;
	border-radius: 16px;
	position: relative;
	padding: 30px;
	background: var(--secondary) url(/nuxt-img/switcher/switcher-bg-mob.webp) no-repeat 50% 50%;
	background-size: auto 100%;

	@include media-breakpoint-up(md) {
		border-radius: 24px;
		height: auto;
		overflow: hidden;
		padding-left: 50px;
		padding-right: 50px;
		background-image: url(/nuxt-img/switcher/switcher-bg.webp);
	}

	.content {
		position: relative;
		z-index: 2;
	}

	&__title {
		@include media-breakpoint-down(md) {
			max-width: 60%;
		}

		@include media-breakpoint-down(sm) {
			max-width: 90%;
		}
	}

	&__sub-title {
		@include media-breakpoint-down(md) {
			max-width: 50%;
		}

		@include media-breakpoint-down(sm) {
			max-width: 140px;
		}
	}

	&__btn {
		width: 100%;
		max-width: 270px;

		@include media-breakpoint-up(md) {
			max-width: 220px;
		}
	}
}
</style>
